<template>
  <div class="general_block">
    <transition name="fade">
      <loader v-if="loader" object="#ffffff" size="5" speed="1.5" bg="#101013" opacity="100" disableScrolling="true"
              name="dots"></loader>
    </transition>
    <div class="container header d-block">
      <router-link to="/" @click="closeMenu" class="d-block">
        <img src="/images/logo.png" class="images_logo" alt="">
      </router-link>
      <img class="burger-menu" @click="changeStatusMenu" src="/images/heroicons-solid_menu-alt-4.svg" alt="">
      <div class="menu" :style="[ menuStatus ? {'right': '0%'} : {} ]">
        <div class="d-flex justify-end">
          <img class="burger-menu" src="/images/close.svg" @click="changeStatusMenu" alt="">
        </div>
        <ul class="ps-0 pt-4 unselectable">
          <li>
            <router-link to="/about" @click="changeStatusMenu">О нас</router-link>
          </li>
<!--          <li>-->
<!--            <router-link to="/team" @click="changeStatusMenu">Наша команда</router-link>-->
<!--          </li>-->
          <li>
            <router-link to="/form" @click="changeStatusMenu">Подать заявку</router-link>
          </li>
          <li>
            <router-link to="/gallery" @click="changeStatusMenu">Фотогалерея</router-link>
          </li>
          <li>
            <router-link to="/result" @click="changeStatusMenu">Хронология</router-link>
          </li>
          <li>
            <router-link to="/news" @click="changeStatusMenu">Статьи</router-link>
          </li>
          <li><a href="https://way-up.proathletesfactory.ru">Way Up</a></li>
          <li>
            <router-link to="/contact" @click="changeStatusMenu">Контакты </router-link>
          </li>
        </ul>
        <div class="d-flex menu_footer">
          <span class="me-auto d-flex"><img class="me-2 icons_social" src="/images/icon_mobile.svg" alt=""><a
              href="tel:89111017878">+7 (911) 101-78-78</a></span>
          <span class="mt-3">
                      <a href="https://vk.com/pro_athletes_factory"><img class="icons_social me-3"
                                                                         src="/images/social-vk.svg" alt="VK"></a>
                      <a href="https://www.youtube.com/@ProAthletesFactory"><img src="/images/social-YT.svg"
                                                                                 class="icons_social" alt="VK"></a>
                    </span>
        </div>
      </div>
    </div>
    <div class="main">
      <router-view/>
    </div>
    <div class="mt-5 footer mt-16">
      <div class="text-center text-white d-flex justify-content-center" style="min-width: 200px;">
        <img class="me-2 icons_social" src="/images/c.svg" alt="">
        <a href="tel:89111017878">+7 (911) 101-78-78</a>
      </div>
      <div class="text-center text-white social_footer">
        <a href="https://vk.com/pro_athletes_factory" class="me-3"><img src="/images/social-vk.svg" class="icons_social"
                                                                        alt="VK"></a>
        <a href="https://www.youtube.com/@ProAthletesFactory"><img src="/images/social-YT.svg" class="icons_social"
                                                                   alt="VK"></a>
      </div>
      <div class="text-center text-white">
        <router-link to="/privacy">Политика конфиденциальности</router-link>
      </div>
      <div class="text-center text-white">© 2023 ProAthletesFactory</div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'App',
  data: () => ({
    menuStatus: false,
    formValues: [],
    loader: true,
  }),
  mounted: function () {
    setTimeout(() => this.loader = false, 1500);
  },
  methods: {
    changeStatusMenu() {
      this.menuStatus = !this.menuStatus;
    },
    closeMenu() {
      this.menuStatus = false;
    },
  }
}
</script>